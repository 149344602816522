import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrap = styled.div`
  ${tw`bg-white m-auto text-center`};
  width: 544px;
  margin-top: 128px;
  padding: 32px 16px;
`;

export const ContactInfoItem = styled.div`
  ${tw`bg-white w-full sm:w-1/3 m-auto text-center p-10 text-sm`};
`;

export const ContactInfoLogo = styled.div`
  ${tw`w-full m-auto`};
`;

export const Image = styled.img`
  ${tw`m-auto pb-8`};
`;

export const Details = styled.div`
  margin: 24px 0;
  text-align: center;
  h4{
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #4D4545;
    margin: 4px 0px;
  }
  p{
    font-size: 14px;
    line-height: 100%;
    align-items: center;
    color: #8B9196;
    margin: 4px 0px;
  }
`;

export const Redirect = styled.div`
  span{
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #4D4545;
  }
  img{
    ${tw`w-10 mx-auto`};
    margin-top: 20px;
  }
`;
