import React from 'react';
import Container from 'components/ui/Container';
import Image from './../../assets/images/thank-you.png';
import Loading from './../../assets/images/loading-circles.gif';
import { graphql, useStaticQuery } from 'gatsby';

import * as Styled from './styles';
import { getQueryVariable } from '../../state/utils';

const ThankYou = ({ locale, location }) => {
  const orderNumber = getQueryVariable('order_number', location);
  const gql = useStaticQuery(graphql`
    query {
      strapiThankYouPage_en: strapiThankYouPage(locale: { eq: "en" }) {
        payment_successful
        confirmation_number
        redirect_text
        track_order
      }
      strapiThankYouPage_zh: strapiThankYouPage(locale: { eq: "zh" }) {
        payment_successful
        confirmation_number
        redirect_text
        track_order
      }
    }
  `);
  const translations = gql[`strapiThankYouPage_${locale}`];

  const redirectToTrackPage = () => {
    setTimeout(function () {
      window.location.href = `/track-order/?order_number=${orderNumber}`;
    }, 5000);
  };

  redirectToTrackPage();

  return (
    <Container>
      <Styled.Wrap className="text-gray-600">
        <Styled.ContactInfoLogo>
          <Styled.Image src={Image} alt="ustore" />
        </Styled.ContactInfoLogo>

        <Styled.Details>
          <h4>{translations.payment_successful}</h4>
          <p>{translations.confirmation_number}</p>
          <p>{orderNumber}</p>
        </Styled.Details>

        <Styled.Redirect>
          <span>{translations.redirect_text}</span>
          <img src={Loading} alt="Loading" />
        </Styled.Redirect>
      </Styled.Wrap>
    </Container>
  );
};

export default ThankYou;
