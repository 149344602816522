import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ThankYou from '../components/ThankYou';
import { I18nextContext } from "gatsby-plugin-react-i18next";

const ThankYouPage: React.FC = (props: any) => {
  const { language } = React.useContext(I18nextContext);
  return (
    <Layout locale={language}>
        <SEO title="Thank You" />
        <ThankYou locale={language} location={props.location}/>
    </Layout>
  );
};

export default ThankYouPage;
